import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Form, Button } from "react-bootstrap";
// import loadable from '@loadable/component'
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../predictive-search/SearchResultBoxHeader"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../utils/property-search"
// import SearchLoqateAddress from '../forms/elements/address-search-header'


// const SearchLoqateAddress = loadable(() => import('../forms/elements/address-search-header'))


const MegaMenu = (props) => {

  // let url = "/";

  // useEffect(() => {
  // $(".search-btn").click(function() {
  //   var searcValue = $(".address").val()
  //   url = "/property/for-sale/"
  //   if(searcValue !== "") {
  //       url += "in-"+searcValue+"/"
  //   } else {
  //       url += "in-kent/"
  //   }
  //   navigate(url);
  // })
  // })
  // const [test, setTest] = useEffect(false);

  function search(val) {
    let url = "/property/for-sale/";

    const main_page = typeof window !== 'undefined' ? window.location : ''
    var page = ''
    if (main_page.pathname) {
      page = main_page.pathname.split("/")[1]
    }


    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    if (searcValue == "") {
      localStorage.removeItem('searchValue');
    }
    if (props.Search_Type == "Residential_Sales") {
      url = "/property/for-sale/"
    }
    else if (props.Search_Type == "Residential_Lettings") {
      url = "/property/to-rent/"
    }
    else if (props.Search_Type == "New_Homes") {
      url = "/property/new-homes/for-sale/"
    }

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      if (props.Search_Type == "New_Homes") {
        url += ""
      }
      else {
        url += "in-kent/"

      }
    }
    navigate(url)
    // window.location.href = url;
    // if (page == "property") {
    // window.location.reload();
    // }
  }


  function valuation() {
    navigate(`/sell-your-property/property-valuation`);
  }
  const [areaVal, setAreaVal] = useState('')

  const queryList = QueryList();

  return (
    <div className={`menu-dropdown ${props.data?.Add_Sub_Menus?.length == "1" ? "single-col" : ""}`}>
      {props.data?.In_Submenu_Show_Search &&
        <div className={`postcode_form search_form_${props.val}`}>
          <h3>{props.data?.Submenu_Search_Title}</h3>
          {props.Search_Type ?
            <div className="form header-predictive-search">
              <SearchResultInputBox
                value={areaVal !== 'kent' ? hyphenToCaptilize(areaVal) : ''}
                type={props.Search_Type}
                setValue={(searchValue, isSelected) => {
                  // refine(removeSpecialChar(searchValue)) 
                  if (isSelected) {
                    localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                  } else {
                    localStorage.removeItem('searchValue');
                  }
                  setAreaVal(searchValue)
                }}
                placeHolder={`Enter town or area`}
                areaList={queryList.areaList}
              />
              <Button variant="primary" className="search-btn" onClick={() => {
                search(areaVal)
              }}>Start Now<i className="icon-arrow"></i></Button>
            </div> :
            <div className="form header-predictive-search">
              <Button variant="secondary" className="search-btn val-cta" onClick={() => { valuation() }}>In-person Valuation<i className="icon-arrow head"></i></Button>
              <Button variant="primary" className="search-btn val-cta" onClick={() => { valuation() }}>Instant Valuation<i className="icon-arrow"></i></Button>
            </div>
          }
        </div>
      }
      <div className="dropdown-link-list">
        {props.data && props.data.Add_Sub_Menus.map((item, i) => (
          <div className="dropdown-box">
            {item.Title &&
              <span>{item.Title}</span>
            }
            <ul>
              {item.Add_here.map((menus, i) => {
                return (
                  <li>
                    <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
