import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'

import { VALUATION_PAGE_URL, BRANCHES_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDE_PAGE_URL, LATEST_NEWS_PAGE_URL, PROPERTY_MANAGEMENT_PAGE_URL, TESTIMONIALS_PAGE_URL, MORTGAGES_PAGE_URL } from '../../common/site/constants'


const PopularSearchDynamic = (props) => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)


  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText = ''
  var area = 'Kent'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText = ''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL = '/property/for-sale/'
  }
  if (props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL = '/property/to-rent/'
  }
  if (props.searchtype == 'newhomesales') {
    searchaction = 'for Sale'
    URL = '/property/new-homes/for-sale/'
    typeText = 'New Property'
  }
  if (props.searchtype == 'newhomerentals') {
    searchaction = 'to Rent'
    URL = '/property/new-homes/to-rent/'
    typeText = 'New Property'
  }
  if (props.searchPtype) {
    ptypeURL = '/type-' + props.searchPtype
    typeText = capitalizeFirstLetter(props.searchPtype).replace("Buy-to-let-investments", "Buy to let investments")
  }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + ' Bedroom ' + typeText
    bedURL = '/' + props.searchBedroomfield + '-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (props.Searcharea && props.Searcharea != 'undefined') {
    searcharea = props.Searcharea.replace("in ", '')
    searchareaText = props.Searcharea.replace(/%20/g, " ").replace("in ", '')
  }
  return (
    <Row>
      {props.searchBedroomfield ?
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-maidstone${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Maidstone</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-gillingham${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Gillingham</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-dartford${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Dartford</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-ashford${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Ashford</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-canterbury${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Canterbury</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-folkestone${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Folkestone</a></li>
          </ul>
        </Col>
        : <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-maidstone${ptypeURL}`}>{typeText} {searchaction} in Maidstone</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-gillingham${ptypeURL}`}>{typeText} {searchaction} in Gillingham</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-dartford${ptypeURL}`}>{typeText} {searchaction} in Dartford</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-ashford${ptypeURL}`}>{typeText} {searchaction} in Ashford</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-canterbury${ptypeURL}`}>{typeText} {searchaction} in Canterbury</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-folkestone${ptypeURL}`}>{typeText} {searchaction} in Folkestone</a></li>
          </ul>
        </Col>
      }

      {searcharea ?
        <Col md={6} lg={3} className="properties-info">
          {props.searchBedroomfield ?
            <ul>
              <li><a className="footer_scroll_top" href={`${URL}in-maidstone${ptypeURL}`}>{typeText} {searchaction} in Maidstone</a></li>
              <li><a className="footer_scroll_top" href={`${URL}in-gillingham${ptypeURL}`}>{typeText} {searchaction} in Gillingham</a></li>
              <li><a className="footer_scroll_top" href={`${URL}in-dartford${ptypeURL}`}>{typeText} {searchaction} in Dartford</a></li>
              <li><a className="footer_scroll_top" href={`${URL}in-ashford${ptypeURL}`}>{typeText} {searchaction} in Ashford</a></li>
              <li><a className="footer_scroll_top" href={`${URL}in-canterbury${ptypeURL}`}>{typeText} {searchaction} in Canterbury</a></li>
              <li><a className="footer_scroll_top" href={`${URL}in-folkestone${ptypeURL}`}>{typeText} {searchaction} in Folkestone</a></li>
            </ul> : props.searchPtype ?
              <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/1-and-more-bedrooms`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/2-and-more-bedrooms`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/3-and-more-bedrooms`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/4-and-more-bedrooms`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/5-and-more-bedrooms`}>5 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/6-and-more-bedrooms`}>6 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
              </ul>
              :
              <ul>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/1-and-more-bedrooms`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/2-and-more-bedrooms`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/3-and-more-bedrooms`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/4-and-more-bedrooms`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/5-and-more-bedrooms`}>5 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
                <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/6-and-more-bedrooms`}>6 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
              </ul>
          }
        </Col> :
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/1-and-more-bedrooms`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/2-and-more-bedrooms`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/3-and-more-bedrooms`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/4-and-more-bedrooms`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/5-and-more-bedrooms`}>5 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
            <li><a className="footer_scroll_top" href={`${URL}in-${searcharea.replace(/\s/g, '-')}/6-and-more-bedrooms`}>6 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</a></li>
          </ul>
        </Col>
      }


      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${URL}in-kent/type-apartment`}>Apartments {searchaction} in {area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-kent/type-flats`}>Flats {searchaction} in {area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-kent/type-house`}>Houses {searchaction} in {area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-kent/type-bungalow`}>Bungalows {searchaction} in {area}</a></li>
          <li><a className="footer_scroll_top" href={`${MORTGAGES_PAGE_URL.alias}`}>Mortgage brokers in {capitalizeFirstLetter(searchareaText)}</a></li>
          <li><a className="footer_scroll_top" href={`${TESTIMONIALS_PAGE_URL.alias}`}>Testimonials</a></li>
        </ul>
      </Col>




      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${AREA_GUIDE_PAGE_URL.alias}`}>{capitalizeFirstLetter(searchareaText)} Area Guides</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate Agents in {capitalizeFirstLetter(searchareaText)}</a></li>
          <li><a className="footer_scroll_top" href={`${VALUATION_PAGE_URL.alias}`}>Property valuation in {capitalizeFirstLetter(searchareaText)}</a></li>
          <li><a className="footer_scroll_top" href={`${PROPERTY_MANAGEMENT_PAGE_URL.alias}`}>Property management in  {capitalizeFirstLetter(searchareaText)}</a></li>
          <li><a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>About Wards of Kent estate agents</a></li>
          <li><a className="footer_scroll_top" href={`${LATEST_NEWS_PAGE_URL.alias}`}>Latest Property News</a></li>
        </ul>
      </Col>
    </Row>
  )

}

export default PopularSearchDynamic;
