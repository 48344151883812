import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../predictive-search/SearchResultBoxHeader"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../utils/property-search"
const Menudata = (props) => {

  function search(val) {
    let url = "/property/for-sale/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()
    if (props.item?.type == "Residential_Sales") {
      url = "/property/for-sale/"
    }
    else if (props.item?.type == "Residential_Lettings") {
      url = "/property/to-rent/"
    }
    else if (props.item?.type == "New_Homes") {
      url = "/property/new-homes/for-sale/"
    }

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      if (props.item?.type == "New_Homes") {
        url += ""
      }
      else {
        url += "in-kent/"
      }
    }

    // window.location.href = url;
    navigate(url);
  }

  function valuation() {
    navigate(`/sell-your-property/property-valuation`);
  }
  const [areaVal, setAreaVal] = useState('')

  const queryList = QueryList();

  return (
    <li
      className={
        props.item.submenu && props.item.isActive
          ? "active card-header card-header-parent"
          : "card-header card-header-parent "
      }
    >
      <div className="d-flex align-items-center mobile-menu-dropdown-wrapper">
        <GenerateLink link={props.item.link}>
          {props.item.name}

        </GenerateLink>
        <div onClick={() => props.handlerOpenMenu(props.index)} className="mobile-menu-dropdown">
          {props.item.submenu && props.item.isActive ? (
            <i className="icon-arrow-up icon-arrow-down-menu"></i>
          ) : (
            props.item.submenu && <i className="icon-arrow-down-menu"></i>
          )}
        </div>
      </div>
      {props.item.submenu && props.item.isActive && (
        <div>

          {props.item?.search &&
            <div className="mobile-menu-reveal-search">
              <div className={`postcode_form search_form_${props.val}`}>
                <h3>{props.item?.text}</h3>
                {props.item?.type ?
                  <form className="header-predictive-search form">
                    <SearchResultInputBox
                      value={areaVal !== 'kent' ? hyphenToCaptilize(areaVal) : ''}
                      setValue={(searchValue) => {
                        // refine(removeSpecialChar(searchValue))
                        setAreaVal(searchValue)
                      }}
                      placeHolder={`Enter town or area`}
                      areaList={queryList.areaList}
                    />
                    <Button variant="primary" className="search-btn" onClick={() => { search(areaVal) }}>Start Now<i className="icon-arrow"></i></Button>
                  </form> : ''
                  // <div className="form header-predictive-search">
                  //   <Button variant="secondary" className="search-btn val-cta" onClick={() => { valuation() }}>In-person Valuation<i className="icon-arrow head"></i></Button>
                  //   <Button variant="primary" className="search-btn" onClick={() => { valuation() }}>Instant Valuation<i className="icon-arrow"></i></Button>
                  // </div>
                }
              </div>
            </div>
          }

          <ul className="submenu-list">
            {props.item.submenu.map((item, i) => (
              <li
                className={
                  item.submenu && item.isActive
                    ? "active card-header card-header-child"
                    : "card-header card-header-child"
                }
                onClick={(e) =>
                  props.handlerOpenSubMenu(e, props.index, i, "submenu")
                }
              >
                <GenerateLink link={item.link}>
                  {item.name}

                  {item.submenu && item.isActive ? (
                    <i className="icon-arrow-up"></i>
                  ) : (
                    item.submenu && <i className="icon-arrow-down"></i>
                  )}
                </GenerateLink>
                {item.submenu && item.isActive && (
                  <ul className="submenu-list">
                    {item.submenu.map((item) => (
                      <li
                        onClick={(e) => e.stopPropagation()}
                        className="menu-accordian"
                      >
                        <a href="#">{item}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default Menudata;